import { DiscoverSearchLocationHelper } from "../helpers/location/DiscoverSearchLocationHelper";
import { IMPORTANT_SOCIAL_PROFILES_BOOSTS } from "../../../constants/constants";

function makeEmptyRequest() {
  return {
    query: {
      bool: {
        must: [],
        should: [],
        must_not: [],
        filter: [],
      },
    },
    size: 20,
    track_total_hits: 1_000_000,
    explain: false,
    highlight: {
      fields: {}
    }
  };
}

function escapeTextSearch(text) {
  return text ? text.replace(/\//g, "\\/").replaceAll("“", "\"").replaceAll("”", "\"").trim() : "";
}

function lowercaseExceptKeywords(inputString) {
  if (!inputString) {
    return "";
  }

  const keywords = ["NOT", "AND", "OR"];
  const words = inputString.split(" ");
  let result = "";

  for (let i = 0; i < words.length; i++) {
    if (keywords.includes(words[i].toUpperCase())) {
      result += words[i] + " ";
    } else {
      result += words[i].toLowerCase() + " ";
    }
  }

  return result.trim();
}

// Harden the keyword search by adding quotes around each word
// NOTE: that way the delimiters such as "-" and "/" are not treated as operators
// and the search is more accurate
function convertQuotes(inputString) {
  if (!inputString) {
    return "";
  }

  let result = "";
  let openQuote = false;
  for (let i = 0; i < inputString.length; i++) {
    let char = inputString[i];
    if (char === "\"") {
      if (openQuote) {
        result += "\")";
        openQuote = false;
      } else {
        result += "(\"";
        openQuote = true;
      }
    } else {
      result += char;
    }
  }

  return result;
}

class ElasticSearchService {
  getCountrySearchTerm(countryCode) {
    const country = DiscoverSearchLocationHelper.getCountryOptions().find((item) => item.value === countryCode);
    return country.label.toLowerCase();
  }

  makeQuery(filters, paging = { limit: 20, offset: 0 }) {
    const req = makeEmptyRequest();

    req.size = paging.limit;
    req.from = paging.offset;
    let mapping = "v7";

    window.lastSearchFilters = filters;
    if (window.devmode) {
      console.log(filters);
      req.explain = true;
    }

    if (window.devmode || window.indextest) {
      if (filters.customIndex && filters.customIndex.split(".").length > 1) {
        mapping = filters.customIndex.trim().split(".")[1];
      }
    }

    // titleExcludeMode
    let forceFilters = ["titleMode", "companyNameMode", "country", "addresses", "social"]
    let activeFilters = Object.keys(filters).filter((key) => !forceFilters.includes(key) && !!filters[key] && (filters[key]?.length || filters[key] === true));

    if (activeFilters.length === 0) {
      req.track_total_hits = false;
    }

    this.buildCommonQuery(req, filters);

    switch (mapping) {
      case "v7":
      case "v6":
      case "v5":
      case "v4":
      case "v3":
        this.buildV5Query(req, filters);
        break
      case "v2":
      default:
        this.buildV2Query(req, filters);
        break
    }

    if (window.devmode) {
      window.lastSearchQuery = req;
      console.log(JSON.stringify(req, null, 2));
    }

    return req;
  }

  buildAdvancedFilters = (filters) => {
    return filters.map((row) => {
      return {
        query_string : {
          query : row.map(item => {
            return `${row.length > 1 ? '(' : ''}${item.operator === 'NOT_INCLUDES' ? 'NOT ' : ''}${item.field}:${escapeTextSearch(item.value)}${row.length > 1 ? ')' : ''}`;
          }).join(' OR '),
          default_operator : "AND",
          boost : 1,
        },
      };
    });
  }

  buildCommonQuery(req, filters) {
    if (filters.country) {
      const stateFilter = {
        term: {
          "location.country.keyword": filters.country,
        },
      };
      req.query.bool.filter.push(stateFilter)
    }

    if (filters.search) {
      const filter = {
        query_string : {
          query: filters.search,
          default_operator : "AND",
          boost : 1,
        },
      };
      req.query.bool.must.push(filter);
    }

    let locationFilters = [];

    if (filters.addresses && filters.addresses.length) {
      filters.addresses.forEach((addr) => {
        if (addr.state) {
          const stateFilter = {
            term: {
              "location.region.keyword": addr.state,
            },
          };
          locationFilters.push(stateFilter);
        }
        else if (addr.zip) {
          const zipFilter = {
            query_string: {
              fields: ["location.postalCode"],
              query: escapeTextSearch(addr.zip),
            },
          };
          if (addr.distance) {
            zipFilter.query_string.distance = addr.distance + "mi";
          }
          locationFilters.push(zipFilter);
        }
        else if (addr.city) {
          const cityFilter = {
            geo_distance: {
              "location.coordinate": {
                lat: addr.city.lat,
                lon: addr.city.lng,
              },
            }
          };
          if (addr.distance) {
            cityFilter.geo_distance.distance = addr.distance + "mi";
          }
          locationFilters.push(cityFilter);
        }
      });
    }

    let highlightSkills = false;
    if (filters.skillsMode && filters.skillsMode === "select") {
      if (filters.skillsMustSelect && filters.skillsMustSelect.length) {
        const query = filters.skillsMustSelect.map((item) => `"${item}"`).join(" AND ");
        const mustSkillsFilter = {
          query_string: {
            fields: ["skills"],
            analyzer: "english_analyzer",
            query: escapeTextSearch(query),
            default_operator: "AND",
            boost: 1,
          },
        }

        req.query.bool.must.push(mustSkillsFilter);
        highlightSkills = true;
      }

      if (filters.skillsShouldSelect && filters.skillsShouldSelect.length) {
        const query = filters.skillsShouldSelect.map((item) => `"${item}"`).join(" OR ");
        const shouldSkillsFilter = {
          query_string: {
            fields: ["skills"],
            analyzer: "english_analyzer",
            query: escapeTextSearch(query),
            default_operator: "AND",
            boost: 0.8,
          },
        }
        
        req.query.bool.should.push(shouldSkillsFilter);
        console.log("here", JSON.stringify(req.query, null, 2));
        highlightSkills = true;
      }
    } else {
      if (filters.skills && filters.skills.length) {
        const skillsFilter = {
          query_string: {
            fields: ["skills"],
            analyzer: "english_analyzer",
            query: escapeTextSearch(filters.skills),
            default_operator: "AND",
            boost: 1,
          },
        }
        req.query.bool.must.push(skillsFilter);
        highlightSkills = true;
      }

      if (filters.skillsShould && filters.skillsShould.length) {
        const skillsShouldFilter = {
          query_string: {
            fields: ["skills"],
            analyzer: "english_analyzer",
            query: escapeTextSearch(filters.skillsShould),
            default_operator: "AND",
            boost: 0.8,
          },
        }
        req.query.bool.should.push(skillsShouldFilter);
        highlightSkills = true;
      }
    }

    if (highlightSkills) {
      req.highlight.fields[`skills`] = {
        type: "plain",
      };
    }

    if (filters.school && filters.school.trim()) {
      let schoolFilter = {
        query_string: {
          fields: ["education.school.name"],
          analyzer: "proper_noun_analyzer",
          query: escapeTextSearch(filters.school),
          boost: 1,
        },
      };

      req.query.bool.must.push(schoolFilter);
    }

    if (filters.degree && filters.degree.length) {
      const degreeFilter = {
        terms: {
          "education.degrees.keyword": filters.degree
        }
      };
      req.query.bool.filter.push(degreeFilter);
    }

    if (filters.major?.length > 0) {
      const majorFilter = {
        bool: {
          should: [
            {
              terms: {
                "education.majors.keyword": filters.major
              }
            },
            {
              // TF we merge minors into majors?
              terms: {
                "education.minors.keyword": filters.major
              }
            }
          ]
        },
      };
      req.query.bool.must.push(majorFilter);
    }

    if (!!filters.firstName) {
      let firstNameFilter = {
        query_string: {
          boost: 1,
          fields: [
            "firstName"
          ],
          analyzer: "proper_noun_analyzer",
          query: escapeTextSearch(filters.firstName)
        }
      };
      if (!filters.lastName) {
        firstNameFilter.query_string.boost = 2;
      }

      req.query.bool.must.push(firstNameFilter);
    }

    if (!!filters.lastName) {
      let lastNameFilter = {
        query_string: {
          boost: 1,
          fields: [
            "lastName"
          ],
          analyzer: "proper_noun_analyzer",
          query: escapeTextSearch(filters.lastName)
        }
      };
      if (!filters.firstName) {
        lastNameFilter.query_string.boost = 2;
      }

      req.query.bool.must.push(lastNameFilter);
    }

    if (filters.withEmailsOnly) {
      const emailsOnlyFilter = {
        exists: {
          field: "searchEmails",
        },
      };
      req.query.bool.filter.push(emailsOnlyFilter);
    }

    if (filters.withPhonesOnly) {
      const phonesOnlyFilter = {
        exists: {
          field: "searchPhoneNumbers",
        },
      };
      req.query.bool.filter.push(phonesOnlyFilter);
    }
    
    const minYears = parseInt(filters.minYears);
    const maxYears = parseInt(filters.maxYears);

    if (!isNaN(minYears) || !isNaN(maxYears)) {
      const range = {}
      if (!isNaN(minYears)) {
        range.gte = minYears;
      }

      if (!isNaN(maxYears)) {
        range.lte = maxYears;
      }
      const yearsExpFilter = {
        range: {
          "yearsExperience": range,
        }
      };

      req.query.bool.must.push(yearsExpFilter);
    }

    if (locationFilters.length) {
      req.query.bool.must.push({
        bool: {
          should: locationFilters,
          minimum_should_match: 1,
        }
      })
    }

    return req;
  }

  buildV2Query(req, filters) {
    if (filters.social && filters.social.length) {
      filters.social.forEach((social) => {
        req.query.bool.should.push({
          constant_score: {
            filter: {
              bool: {
                should: [
                  {
                    term: {
                      "socialProfiles.site": social,
                    }
                  }
                ]
              }
            },
            boost: IMPORTANT_SOCIAL_PROFILES_BOOSTS[social] || 0.1
          }
        });
      });
    }

    if (filters.title && filters.title.trim() !== "") {
      let titleQueryString = escapeTextSearch(filters.title);
      if (filters.title.includes("\"")) {
        titleQueryString = convertQuotes(lowercaseExceptKeywords(filters.title));
      }

      let experienceField = filters.titleMode === "current" ? "currentExperiences" : "experiences";
      let titleFilter = {
        query_string: {
          fields: [`${experienceField}.title^2`],
          query: titleQueryString,
          default_operator: "AND",
          boost: 1,
        },
      };

      if (filters.titleMode !== "allJobs") {
        req.query.bool.should.push(...[
          {
            exists: {
              field: `${experienceField}.startDate`,
              boost: 1.5,
            },
          },
        ])
      }

      req.query.bool.must.push(titleFilter);
      req.highlight.fields[`${experienceField}.title`] = {
        type: "plain",
      };
    }

    if (filters.employer && filters.employer.trim()) {
      let experienceField = filters.companyNameMode === "current" ? "currentExperiences" : "experiences";
      let employerFilter = {
        query_string: {
          fields: [`${experienceField}.company.name`],
          analyzer: "proper_noun_analyzer",
          query: escapeTextSearch(filters.employer),
          boost: 0.5,
        },
      };

      req.query.bool.must.push(employerFilter);
      req.highlight.fields[`${experienceField}.company.name`] = {
        type: "plain",
      };
    }

    if (filters.industries && filters.industries.length) {
      let experienceField = false ? "currentExperiences" : "experiences";
      const industryFilter = { terms: {} };
      industryFilter.terms[`${experienceField}.company.industry.keyword`] = filters.industries;

      req.query.bool.filter.push(industryFilter);
      req.highlight.fields[`${experienceField}.company.industry.keyword`] = {
        type: "plain",
      };
    }

    if (filters.currentCompanySize && filters.currentCompanySize.length) {
      const companySizeFilter = {
        terms: {
          "currentExperiences.company.size": filters.currentCompanySize
        }
      }
      req.query.bool.must.push(companySizeFilter);
    }

    return req;
  }

  buildV5Query(req, filters) {
    if (filters.social && filters.social.length) {
      filters.social.forEach((social) => {
        req.query.bool.should.push({
          constant_score: {
            filter: {
              bool: {
                should: [
                  {
                    term: {
                      "socialProfiles.site": social,
                    }
                  }
                ]
              }
            },
            boost: IMPORTANT_SOCIAL_PROFILES_BOOSTS[social] || 0.1
          }
        });
      });
    }

    if (filters.title && filters.title.trim() !== "") {
      let titleQueryString = escapeTextSearch(filters.title);
      if (filters.title.includes("\"")) {
        titleQueryString = convertQuotes(lowercaseExceptKeywords(filters.title));
      }

      let titleFilter = {
        nested: {
          path: "experiences",
          query: {
            function_score: {
              query: {
                bool: {
                  must: [
                    {
                      query_string: {
                        default_field: "experiences.title",
                        query: titleQueryString,
                        boost: 3.0,
                        default_operator: "AND"
                      }
                    }
                  ],
                  should: [
                    {
                      constant_score: {
                        filter: {
                          bool: {
                            must_not: {
                              exists: {
                                field: "experiences.endDate"
                              }
                            }
                          }
                        },
                        boost: 1.5
                      }
                    },
                    {
                      distance_feature: {
                        field: "experiences.endDate",
                        pivot: "120d",
                        origin: "now",
                        boost: 1.5
                      }
                    },
                    {
                      distance_feature: {
                        field: "experiences.startDate",
                        pivot: "120d",
                        origin: "now",
                        boost: 1.0
                      }
                    }
                  ]
                }
              },
              boost_mode: "multiply",
              boost: 3.5
            }
          },
        },
      };

      if (filters.titleMode !== "allJobs") {
        titleFilter.nested.query.function_score.query.bool.must.push(
          {
            bool: {
              must_not: {
                exists: {
                  field: "experiences.endDate"
                }
              }
            }
          }
        );
      }

      req.query.bool.must.push(titleFilter);
      req.highlight.fields[`experiences.title`] = {
        type: "plain",
      };
    }

    if (window.devmode && filters.titleExclude && filters.titleExclude.trim() !== "") {
      let titleExcludeQueryString = escapeTextSearch(filters.titleExclude);
      if (filters.titleExclude.includes("\"")) {
        titleExcludeQueryString = convertQuotes(lowercaseExceptKeywords(filters.titleExclude));
      }

      let titleExcludeFilter = {
        nested: {
          path: "experiences",
          query: {
            function_score: {
              query: {
                bool: {
                  must: [
                    {
                      query_string: {
                        default_field: "experiences.title",
                        query: titleExcludeQueryString,
                        boost: 3.0,
                        default_operator: "AND"
                      }
                    }
                  ]
                }
              },
              boost_mode: "multiply",
              boost: 3.5
            }
          },
        },
      };

      // if (filters.titleExcludeMode !== "allJobs") {
      //   titleExcludeFilter.nested.query.function_score.query.bool.must.push(
      //     {
      //       bool: {
      //         must_not: {
      //           exists: {
      //             field: "experiences.endDate"
      //           }
      //         }
      //       }
      //     }
      //   );
      // }

      req.query.bool.must_not.push(titleExcludeFilter);
    }

    if (window.devmode && filters.versionFilter && filters.versionFilter.trim() !== "") {
      let versionFilter = {
        term: {
          "version": filters.versionFilter.trim(),
        },
      };
      req.query.bool.filter.push(versionFilter);
    }

    if (filters.employer && filters.employer.trim()) {
      let employerFilter = {
        nested: {
          path: "experiences",
          query: {
            bool: {
              must: [
                {
                  query_string: {
                    fields: [`experiences.company.name`],
                    // analyzer: "proper_noun_analyzer",
                    query: escapeTextSearch(filters.employer),
                    boost: 0.5,
                  }
                }
              ],
              must_not: [],
            }
          },
        },
      };

      if (filters.companyNameMode !== "allJobs") {
        employerFilter.nested.query.bool.must_not.push({
          exists: {
            field: "experiences.endDate"
          }
        });
      }
      
      req.query.bool.must.push(employerFilter);
      req.highlight.fields[`experiences.company.name`] = {
        type: "plain",
      };
    }

    if (filters.industries && filters.industries.length) {
      let industryFilter = {
        nested: {
          path: "experiences",
          query: {
            function_score: {
              query: {
                bool: {
                  must: [
                    {
                      terms: {
                        "experiences.company.industry.keyword": filters.industries
                      }
                    }
                  ],
                  must_not: [
                  ]
                }
              },
              boost_mode: "multiply"
            }
          },
        },
      };

      // if (filters.companyNameMode !== "allJobs") {
      //   employerFilter.nested.query.function_score.query.bool.must_not.push({
      //     exists: {
      //       field: "experiences.endDate"
      //     }
      //   });
      // }
      
      req.query.bool.must.push(industryFilter);
      req.highlight.fields[`experiences.company.industry.keyword`] = {
        type: "plain",
      };
    }

    if (filters.currentCompanySize && filters.currentCompanySize.length) {
      let companySizeFilter = {
        nested: {
          path: "experiences",
          query: {
            function_score: {
              query: {
                bool: {
                  must: [
                    {
                      terms: {
                        "experiences.company.size": filters.currentCompanySize
                      }
                    }
                  ],
                  must_not: [
                    {
                      exists: {
                        field: "experiences.endDate"
                      }
                    }
                  ]
                }
              },
              boost_mode: "multiply"
            }
          },
        },
      };
      req.query.bool.must.push(companySizeFilter);
    }

    return req;
  }
}

export const elasticSearchService = new ElasticSearchService();
