import React, { useState } from "react";
import { css, cx } from "emotion/macro";
import Button from "react-bootstrap/Button";

import CompanyImg from "../company/CompanyImg";
import RowDetailSection from "../row/RowDetailSection";
import { xs, sm, md, lg, lgU, xlgU } from "../../css/mediaQueries";
import RowListItem from "../row/RowListItem";
import ProjectDetails from "../../components/modals/projectDetails";

import { useModalNavigator } from "../../contexts/modalNavigator";
import { Icon } from "../DiscoverNew/UI/IconComponent/Icon";
import { useAuthController } from "../../contexts/AuthProvider";
import { PermissionEnum as PermissionsEnum, permissionService } from "../../screens/projects/PermissionService";
import { CreateProjectModal } from "../modals/CreateProjectModal/CreateProjectModal";

function CompanyRow({
  index,
  company,
  openCompanyDetailModal,
  buildTotalCountString,
  getCompanyInitials,
  refreshCompanies,
  uiSettings,
  handleDelete
}) {
  const { dispatch } = useModalNavigator();
  const { user } = useAuthController();

  const canDelete = permissionService.can(null, PermissionsEnum.companyDelete);
  const canCreateProject = permissionService.can(null, PermissionsEnum.projectCreate);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isProjectDetailsModalOpen, setIsProjectDetailsModalOpen] = useState(false);
  const [selectedProjectInitials, setSelectedProjectInitials] = useState("");

  const toggleCreateModalWindow = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setIsCreateModalOpen(!isCreateModalOpen);
  };

  const onCreateProjectClose = async () => {
    if (!refreshCompanies) return;
    setIsCreateModalOpen(!isCreateModalOpen);
    refreshCompanies();
  };

  const onListItemNameClick = (e, project) => {
    if (!e || !project) return;
    e.preventDefault();
    e.stopPropagation();

    const initials = getCompanyInitials(company?.name);
    setSelectedProjectInitials(initials);

    dispatch({ type: "SET_CURRENT_PROJECT", payload: { ...project } });
    dispatch({ type: "SET_CURRENT_COMPANY", payload: { ...company } });

    setIsProjectDetailsModalOpen(!isProjectDetailsModalOpen);
  };

  const toggleProjectDetailsModalWindow = () => {
    setIsProjectDetailsModalOpen(!isProjectDetailsModalOpen);

    // Reset Provider State and Local Project State
    dispatch({ type: "SET_CURRENT_PROJECT", payload: null });
    dispatch({ type: "SET_CURRENT_COMPANY", payload: null });
  };

  const handleViewMore = (e) => {
    if (e) {
      e.stopPropagation();
    }
    openCompanyDetailModal(company);
  };

  return (
    <>
      <div id="company-row" key={index} className={cx("candidate-row", styles.candidateRow)}>
        <div className={cx("candidate-row-wrapper", styles.candidateRowWrapper)}>
          {/* Profile Details Container */}
          <div className={styles.firstColumn} onClick={() => openCompanyDetailModal(company)}>
            {/* Profile Container */}
            <div className={styles.profileContainerWrapper}>
              <div className={cx("profile-image-container", styles.profileImgContainer)}>
                <CompanyImg
                  company={company}
                  initials={getCompanyInitials(company?.name)}
                  style={{ width: "60px", height: "60px" }}
                />
              </div>
              {/* Company Profile Section */}
              <div className={styles.profileContainer}>
                <div className={styles.profileInfoContainer}>
                  <div className={cx("line-one", styles.profileLineOne, styles.truncateText)}>{company?.name}</div>
                  <span className={cx(styles.profileLineTwo)}>
                    {buildTotalCountString(
                      company?.projectTotal,
                      uiSettings?.mappings?.project,
                      uiSettings?.mappings?.projects
                    )}
                  </span>
                  <span className={cx(styles.profileLineThree, "candidates-text")}>
                    {buildTotalCountString(
                      company?.candidateTotal,
                      uiSettings?.mappings?.candidate,
                      uiSettings?.mappings?.candidates
                    )}
                  </span>
                </div>

                {/* Mobile Add Project Button */}
                {canCreateProject && (
                  <div className={cx(styles.mobileAddButtonAndCountContainer, "company-project-action-container")}>
                    <div className={styles.addButtonAndCountContainer}>
                      <Button
                        className={styles.addButton}
                        variant="outline-primary"
                        onClick={(e) => {
                          toggleCreateModalWindow(e);
                        }}
                      >
                      <span
                        className={cx(
                          "standard-theme-text",
                          "plus-button mr-1",
                          css`
                            font-size: 20px;
                            letter-spacing: -0.24px;
                          `,
                        )}
                      >
                        +
                      </span>
                        <span
                          className={cx(
                            "standard-theme-text",
                            css`
                              font-family: Montserrat-Medium !important;
                              font-size: 11.5px;
                              letter-spacing: -0.24px;
                            `,
                          )}
                        >
                        {`Add ${uiSettings?.mappings?.project}`}
                      </span>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Mobile Horizontal Divider */}
          <div className={cx(styles.horizontalDivider)}></div>
          {/* Vertical Divider */}
          <div className={cx("vertical-divider", styles.verticalDivider)}></div>
          {/* Projects Container */}
          <div className={cx(styles.secondColumn, "experience-skills-container")}>
            <div className={styles.rowDetailContainer}>
              <RowDetailSection title={uiSettings?.mappings.projects} showViewMore={false} marginBottom="0px">
                <RowListItem
                  entries={company?.projects?.filter(p => !p.archivedAt)}
                  maxEntries={3}
                  emptyListMessage={`No ${uiSettings?.mappings.projects}`}
                  onListItemNameClick={onListItemNameClick}
                  handleViewMore={handleViewMore}
                />
              </RowDetailSection>
            </div>
          </div>
          {/* Vertical Divider */}
          <div className={cx("vertical-divider", styles.verticalDivider)}></div>
          {/* Add To Project and Company Count Section */}
          <div className={cx(styles.thirdColumn, "company-project-action-container")}>
            <div className={styles.addButtonAndCountContainer}>
              {canCreateProject && (
                <Button
                  className={styles.addButton}
                  variant="outline-primary"
                  onClick={(e) => {
                    toggleCreateModalWindow(e);
                  }}
                >
                <span
                  className={cx(
                    "standard-theme-text",
                    "plus-button mr-1",
                    css`
                      font-size: 20px;
                      letter-spacing: -0.24px;
                    `
                  )}
                >
                  +
                </span>
                  <span
                    className={cx(
                      "standard-theme-text",
                      css`
                      font-family: Montserrat-Medium !important;
                      font-size: 13.1px;
                      letter-spacing: -0.24px;
                    `
                    )}
                  >
                  {`Add ${uiSettings?.mappings?.project}`}
                </span>
                </Button>
              )}
              {canDelete && (
                <Button
                  className={styles.deleteButton}
                  variant="outline-primary"
                  onClick={(e) => {
                    handleDelete(e, company);
                  }}
                >
                  <Icon name='fa-trash' className={
                    css`
                      position: relative;
                      top: 1px;
                      svg { width: 11px; height: auto } 
                      svg path { fill: var(--c-red)}`
                  } />
                  <span
                    className={cx(
                      "standard-theme-text",
                      css`
                        margin-left: 6px;
                        font-family: Montserrat-Medium !important;
                        font-size: 13.1px;
                        letter-spacing: -0.24px;
                      `
                    )}
                  >
                    Delete Company
                  </span>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {isCreateModalOpen && (
        <CreateProjectModal
          onClose={onCreateProjectClose}
          onCreated={onCreateProjectClose}
          defaultCompany={company}
        />
      )}
      {/* Project Details Modal Window */}
      {isProjectDetailsModalOpen && (
        <ProjectDetails
          uiSettings={uiSettings}
          toggleModalWindow={toggleProjectDetailsModalWindow}
          viewModalOpenState={isProjectDetailsModalOpen}
          currentProjectInitials={selectedProjectInitials}
        />
      )}
    </>
  );
}

const styles = {
  candidateRow: css`
    min-height: 208px;
    padding: 27.5px 13.6% 18.8px 13.6%;
    ${xlgU} {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 72.8%;
        border-bottom: 1px solid #d6d6d6;
        margin: auto;
        left: 0;
        right: 0;
        max-width: 1200px;
      }
    }
    ${lgU} {
      height: 208px;
      .contact-bubble-container {
        height: 18px;
      }
    }
    ${lg} {
      padding-right: 20px;
      padding-left: 20px;
      border-bottom: 1px solid #d6d6d6;
    }
    ${md} {
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: initial;
      padding: 20px;
      padding-top: 39px;
      &:first-child {
        border-top: 1px solid #d6d6d6;
      }
    }
    ${xs} {
      padding: 21px 24px 16px 31px;
    }
  `,
  candidateRowWrapper: css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    // border-bottom: 1px solid #d6d6d6;
    ${xlgU} {
      max-width: 1200px;
      margin: auto;
    }
    ${md} {
      display: initial;
      width: 100%;
      margin: auto;
    }
  `,
  firstColumn: css`
    display: flex;
    flex-flow: column;
    width: 42.4%;
    height: 100%;
    align-items: center;
    cursor: pointer;

    width: calc(45% - 53px);
    ${md} {
      height: initial;
      width: 100%;
    }
    &:hover .line-one {
      color: #208bfe;
      cursor: pointer;
    }
  `,
  profileContainerWrapper: css`
    display: flex;
    width: 100%;

    ${md} {
      width: 100%;
      justify-content: center;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
    ${sm} {
      flex-flow: column;
      align-items: center;
    }
  `,
  profileImgContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    ${lgU} {
      width: 83px;
      justify-content: flex-start;
      margin-top: -20px;
    }
    ${sm} {
      margin: initial;
    }
    ${xs} {
      width: 100px;
    }
  `,
  profileContainer: css`
    height: 100%;
    display: flex;
    flex-flow: column;
    ${md} {
      width: 100%;
      padding: initial;
      margin-bottom: 8px;
      align-items: center;
    }
    ${sm} {
      width: 100%;
    }
  `,
  profileInfoContainer: css`
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;
    ${md} {
      text-align: center;
    }
    ${sm} {
      align-items: center;
      text-align: center;
    }
  `,

  companyGrid: css`
    display: flex;
  `,
  profileLineOne: css`
    font-family: Montserrat;
    font-weight: bold;
    font-stretch: normal;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 5px;
    letter-spacing: -1.02px;
    ${md} {
      text-align: center;
    }
  `,
  profileLineTwo: css`
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.66px;
    color: #373737;
    margin-bottom: 5px;
    ${md} {
      text-align: center;
    }
  `,
  profileLineThree: css`
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.63px;
    color: #a8a8a8;
    ${md} {
      text-align: center;
    }
  `,
  addButtonContainer: cx("col-sm-12", "col-md-4", "col-lg-3", "col-xl-3", "my-4", "my-md-0"),
  truncateText: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
  `,
  addButton: css`
    padding-left: 0px !important;
    padding: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 224px;
    height: 36px;
    border: 1px solid #208bfe !important;
    color: #208bfe;
    border-radius: 25px !important;
    &:hover {
      background-color: #208bfe;
      color: #ffffff !important;
      cursor: pointer;
    }
    &:hover .plus-button {
      color: #ffffff !important;
    }
    ${xs} {
      width: 196px;
      height: 32px;
    }
  `,
  deleteButton: css`
    margin-top: 12px;
    padding: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    border: 0 !important;
    color: var(--c-red) !important;
    border-radius: 25px !important;
    ${xs} {
      width: 196px;
      height: 32px;
    }
    &:hover {
      text-decoration: none;
      background-color: #ffffff !important;
      border: 0 !important;
      color: var(--c-red) !important;
    }
  `,
  horizontalDivider: css`
    display: none;
    ${md} {
      width: 263px;
      height: 1px;
      background-color: #d8d8d8;
      display: block;
      align-self: center;
      margin: 6px auto 15px auto;
    }
  `,
  verticalDivider: css`
    width: 1px;
    background-color: #d8d8d8;
    margin-top: 10px;
    margin-bottom: 23px;
    ${md} {
      display: none;
    }
  `,
  secondColumn: css`
    width: 30.5%;
    height: 100%;
    padding-top: 5px;
    display: flex;
    flex-flow: column;
    padding-right: 10px;
    padding-left: 10px;
    align-items: center;
    ${md} {
      height: initial;
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }
    ${xs} {
      margin-top: 0px;
    }
  `,
  rowDetailContainer: css`
    height: 100%;
    width: 100%;
    ${md} {
      display: flex;
      flex-flow: column;
      justify-content: initial;
      align-items: center;
      width: 30%;
      margin-left: auto;
      margin-right: auto;
      min-width: 263px;
    }
    ${xs} {
      justify-content: initial;
      align-items: initial;
    }
  `,
  addButtonAndCountContainer: css`
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    ${lgU} {
      margin-left: 25px;
      margin-top: -10px;
    }
    ${md} {
      margin-left: initial;
      margin-top: initial;
      margin-bottom: 20px;
    }
  `,
  thirdColumn: css`
    width: 28.7%;
    height: 100%;
    align-self: center;
    ${md} {
      display: none;
    }
  `,

  mobileAddButtonAndCountContainer: css`
    display: none;
    ${md} {
      display: flex;
      width: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
    ${xs} {
      margin-bottom: initial;
    }
  `,
};

export default CompanyRow;
