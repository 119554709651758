import { css } from "emotion/macro";
import { ErrorsType, useForm } from "hooks/useForm";
import * as React from "react";
import { useState } from "react";

import { Modal } from "react-bootstrap";
import { CompanyInterface, companyService } from "../../../screens/companies/CompanyService";
import { ProjectInterface } from "../../../screens/projects/CandidateService";
import { projectService } from "../../../screens/projects/ProjectService";
import { logger } from "../../../services/Logger";
import CloseButton from "../../button/CloseButton";
import { useNotifications } from "../../DiscoverNew/contexts/Notification/notifications";
import Stepper from "../../ui/Stepper/Stepper";
import { modalStyles } from "../modalStyles";
import { CreateProjectFormInfo } from "./CreateProjectFormInfo";
import { CreateProjectFormInterviewProcess } from "./CreateProjectFormInterviewProcess";

type Props = {
  onClose: () => void
  onCreated: (project: ProjectInterface) => void
  defaultCompany?: CompanyInterface
}

type FormValues = {
  name: string
  description: string
  tags: { value: string, label: string }[]
  interviewProcess?: { value: string, label: string }
  company?: { value: string, label: string, __isNew__?: boolean }
  companyWebsite?: string
}

const getInitialValues = ({ company }: { company?: CompanyInterface}): FormValues => ({
  name : "",
  description : "",
  tags : [],
  interviewProcess : undefined,
  company : company ? { value: company.id + '', label: company.name } : undefined,
  companyWebsite : "",
});


export const CreateProjectModalContext = React.createContext({
  values : getInitialValues({}),
  errors : {} as Partial<ErrorsType<FormValues>>,
  onChangeValues : (values: Partial<FormValues>) => {},
  loading: false,
});

export function CreateProjectModal(props: Props) {

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const { values, errors, setErrors, onChangeValues } = useForm<FormValues>(getInitialValues({ company: props.defaultCompany }));
  const notifications = useNotifications();

  const onGoToStages = (values: Partial<FormValues>) => {
    onChangeValues(values);
    setStep(2);
  }

  const onClose = () => props.onClose();

  const resolveCompanyId = async () => {
    let companyId = parseInt(values.company!.value);
    if (values.company!.__isNew__) {
      const company = await companyService.create({ name: values.company!.label, website: values.companyWebsite });
      companyId = company.id;
    }
    return companyId;
  }


  const onCreate = async (interviewProcessId: string) => {
    setLoading(true);
    try {
      const project = await projectService.create({
        name: values.name,
        description: values.description,
        tags: values.tags.map((item) => item.label.trim()).filter(Boolean),
        companyId: await resolveCompanyId(),
        interviewProcessId: parseInt(interviewProcessId),
      })
      // @ts-ignore
      props.onCreated(project);
    } catch (err) {
      logger.error("Could not create project", err);
      // @ts-ignore
      if (err.response?.data?.message?.includes('duplicate')) {
        notifications.showError("Project with this name already exists within the organization");
      } else {
        notifications.showError("Could not create project");
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal show={true} onHide={onClose} centered className={css`
        .modal-dialog {
          max-width: 560px;
        }
      `}>
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight : "initial" }}>
        <h3 className={modalStyles.modalTitle}>
          Create a new project
        </h3>
        <Stepper steps={["Information", "Interview Process"]} activeStep={step} onStepClick={setStep} />
        <CreateProjectModalContext.Provider value={{
          values,
          errors,
          onChangeValues,
          loading
        }}>
          {step === 1 && <CreateProjectFormInfo allowCreateCompany buttonTitle="Go to Interview Process" initialValues={values} onSubmit={onGoToStages} />}
          {step === 2 && <CreateProjectFormInterviewProcess onSubmit={onCreate} />}
        </CreateProjectModalContext.Provider>
      </div>
    </Modal>
  );
}